import {
    Box, Typography,
} from '@mui/material';
import React from 'react';
import {UploadCsvBox} from 'components/uploadCsvBox/UploadCsvBox';

export const UploadsScreen = () => {
    return (
        <Box>
            <Typography component="h1" variant="h3">
                Uploads
            </Typography>

            <UploadCsvBox
                title="Upload IMDB Crawl Request CSV"
                note="You must upload CSV that 1st row contains field names (imdb_id, priority). Make sure that priority is number"
                uploadApiPath="/admin-api/upload/imdb-crawl-request/upload-csv"
            />
        </Box>
    );
};
