import {
    Box, Button, Input, Paper, Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {MovieToProofResponse} from 'api/movie/MovieToProofResponse';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {MovieToProofPayload} from 'api/movie/MovieToProofPayload';

export const MovieNamesScreen = () => {
    const [movieToProof, setMovieToProof] = useState<MovieToProofResponse | undefined>(undefined);
    const [nameCsInput, setNameCsInput] = useState<string>('');
    const [nameEnInput, setNameEnInput] = useState<string>('');
    const [justwatchUrl, setJustWatchUrl] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const loadNextMovieToProof = async () => {
        const response = await fetch(
            '/admin-api/proof-read/movie-names/get-next-to-proof',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const movieToProofResponse: MovieToProofResponse = await response.json();

        setMovieToProof(movieToProofResponse);
        setNameCsInput(movieToProofResponse.status === 'OK' ? movieToProofResponse.nameCs ?? '' : '');
        setNameEnInput(movieToProofResponse.status === 'OK' ? movieToProofResponse.nameEn ?? '' : '');
        setJustWatchUrl(movieToProofResponse.status === 'OK' ? movieToProofResponse.justwatchUrl ?? '' : '');
    };

    useEffect(() => {
        loadNextMovieToProof();
    }, []);

    const handleSubmit = async () => {
        if (!movieToProof) {
            throw new Error('Cannot send form when movie is unset');
        }

        if (movieToProof.status === 'NO_MOVIE') {
            throw new Error('Cannot send form with state no movie');
        }

        setIsSubmitting(true);
        const body: MovieToProofPayload = {
            id: movieToProof.id,
            nameCs: nameCsInput,
            nameEn: nameEnInput,
            justwatchUrl,
        };

        await fetch(
            '/admin-api/proof-read/movie-names/approve-names',
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        );

        setIsSubmitting(false);
        loadNextMovieToProof();
    };

    const renderContent = () => {
        if (movieToProof === undefined) {
            return 'Loading...';
        }

        if (movieToProof.status === 'NO_MOVIE') {
            return 'There is no movie to proof';
        }

        return (
            <Paper sx={{mt: 5, p: 2}}>
                {movieToProof.originalImageSource && (
                    <Box mt={1}>
                        Image:
                        <Box sx={{width: '200px', height: '200px'}}>
                            <img
                                src={movieToProof.originalImageSource}
                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                alt={movieToProof.imdbName}
                            />
                        </Box>
                    </Box>
                )}
                <Box mt={1}>
                    <a target="_blank" href={`https://www.csfd.cz/hledat/?q=${movieToProof.imdbName}`}>
                        Link to ČSFD
                    </a>
                    <br />
                    <a target="_blank" href={`https://www.imdb.com/title/${movieToProof.imdbId}`}>
                        Link to IMDB database
                    </a>
                    <br />
                    <a target="_blank" href={`https://www.justwatch.com/cz/vyhledání?q=${movieToProof.imdbName}`}>
                        Link to JustWatch search
                    </a>
                </Box>

                <Box mt={3}>
                    <strong>IMDB Name (readonly):</strong>
                    {' '}
                    <Input
                        type="text"
                        value={movieToProof.imdbName}
                        fullWidth
                        disabled
                    />
                </Box>
                <Box mt={3}>
                    <strong>Year (readonly):</strong>
                    {' '}
                    <Input
                        type="text"
                        value={movieToProof.year}
                        fullWidth
                        disabled
                    />
                </Box>

                <Box mt={1}>
                    <strong>Name CS:</strong>
                    <br />
                    (Leave empty, if doesn't have czech name)
                    <Input
                        type="text"
                        value={nameCsInput}
                        onChange={(e) => setNameCsInput(e.target.value)}
                        fullWidth
                    />
                </Box>

                <Box mt={1}>
                    <strong>Name EN/Original</strong>
                    <br />
                    (Leave empty, if doesn't have english/original name)
                    <Input
                        type="text"
                        value={nameEnInput}
                        onChange={(e) => setNameEnInput(e.target.value)}
                        fullWidth
                    />
                </Box>

                <Box mt={1}>
                    <strong>Justwatch URL</strong>
                    <br />
                    (Make sure to enter CZ version. Leave empty if doesn't have just watch record)
                    <Input
                        type="text"
                        value={justwatchUrl}
                        placeholder="ex.: https://www.justwatch.com/cz/film/harry-potter-a-kamen-mudrcu"
                        onChange={(e) => setJustWatchUrl(e.target.value)}
                        fullWidth
                    />
                </Box>

                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => { handleSubmit(); }}
                    disabled={isSubmitting}
                >
                    Approve
                </Button>
            </Paper>
        );
    };

    return (
        <Box>
            <Typography component="h1" variant="h3">
                Proof Movie Names
            </Typography>

            {movieToProof?.status === 'OK' && (
                <div>
                    Remaining to proof:
                    {' '}
                    <strong style={{color: 'red'}}>{movieToProof.toProofreadCount}</strong>
                    <br />
                    Ready to publish:
                    {' '}
                    <strong style={{color: 'green'}}>{movieToProof.readyToPublishCount}</strong>
                </div>
            )}

            {renderContent()}
        </Box>
    );
};
