import {
    Box, Button, Container, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {fetchLogin} from 'api/user/login/fetch';
import {setCookie} from 'library/cookie/set';
import {CookiesNames} from 'library/cookie/cookiesNames';

export const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async () => {
        const response = await fetchLogin(username, password);
        setCookie(CookiesNames.JWT_KEY, response.access_token);
        window.location.reload();
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Admin - Sign in
                </Typography>
                <Box
                    component="form"
                    sx={{ mt: 4 }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        label="Username"
                        name="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        sx={{mt: 2}}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}

                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};
