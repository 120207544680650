import {
    Box, Button, Input, Paper, Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';
import {MovieToProofPayload} from 'api/movie/MovieToProofPayload';
import {MovieDescriptionToProofResponse} from 'api/movie/MovieDescriptionToProofResponse';
import {MovieDescriptionToProofPayload} from 'api/movie/MovieDescriptionToProofPayload';

export const MovieDescriptionScreen = () => {
    const [movieToProof, setMovieToProof] = useState<MovieDescriptionToProofResponse | undefined>(undefined);
    const [descriptionInput, setDescriptionInput] = useState('');
    const [reasonsInputs, setReasonsInputs] = useState<{value: string, id: number}[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const loadNextMovieToProof = async () => {
        const response = await fetch(
            '/admin-api/proof-read/description/get-next-to-proof',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
            },
        );
        const movieToProofResponse: MovieDescriptionToProofResponse = await response.json();

        setMovieToProof(movieToProofResponse);
        setDescriptionInput('');
        setReasonsInputs(movieToProofResponse.status === 'OK' ? movieToProofResponse.reasonsToWatch.map((_) => ({id: _.id, value: ''})) : []);
    };

    useEffect(() => {
        loadNextMovieToProof();
    }, []);

    const handleSubmit = async () => {
        if (!movieToProof) {
            throw new Error('Cannot send form when movie is unset');
        }

        if (movieToProof.status === 'NO_MOVIE') {
            throw new Error('Cannot send form with state no movie');
        }

        if (descriptionInput === '') {
            alert('Description must be filled');
            return;
        }

        if (reasonsInputs.find((_) => _.value === '') !== undefined) {
            alert('All reasons must be filled');
            return;
        }

        setIsSubmitting(true);
        const body: MovieDescriptionToProofPayload = {
            id: movieToProof.id,
            description: descriptionInput,
            reasons: reasonsInputs.map((reasonInput) => ({
                reason: reasonInput.value,
                id: reasonInput.id,
            })),
        };

        await fetch(
            '/admin-api/proof-read/description/approve-description',
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        );

        setIsSubmitting(false);
        loadNextMovieToProof();
    };

    const renderContent = () => {
        if (movieToProof === undefined) {
            return 'Loading...';
        }

        if (movieToProof.status === 'NO_MOVIE') {
            return 'There is no movie to proof';
        }

        return (
            <Paper sx={{mt: 5, p: 2}}>
                {movieToProof.originalImageSource && (
                    <Box mt={1}>
                        Image:
                        <Box sx={{width: '200px', height: '200px'}}>
                            <img
                                src={movieToProof.originalImageSource}
                                style={{maxWidth: '100%', maxHeight: '100%'}}
                                alt={movieToProof.nameCs ?? ''}
                            />
                        </Box>
                    </Box>
                )}
                {movieToProof.justWatchUrl && (
                    <Box mt={1}>
                        <a target="_blank" href={`https://www.imdb.com/title/${movieToProof.imdbId}`}>
                            Link to IMDB database
                        </a>
                        <br />
                        <a target="_blank" href={`${movieToProof.justWatchUrl}`}>
                            Link to JustWatch
                        </a>
                    </Box>
                )}

                <Box mt={3}>
                    <strong>Name (readonly):</strong>
                    {' '}
                    <Input
                        type="text"
                        value={movieToProof.nameCs || movieToProof.nameEn || 'NAME IS MISSING'}
                        fullWidth
                        disabled
                    />
                </Box>
                <Box mt={3}>
                    <strong>Year (readonly):</strong>
                    {' '}
                    <Input
                        type="text"
                        value={movieToProof.year}
                        fullWidth
                        disabled
                    />
                </Box>

                <Box mt={1}>
                    <strong>AI generated description:</strong>
                    <Input
                        type="text"
                        value={movieToProof.description}
                        fullWidth
                        disabled
                        multiline
                    />
                </Box>

                <Box mt={1}>
                    <strong>Rewrited description:</strong>
                    <Input
                        type="text"
                        value={descriptionInput}
                        onChange={(e) => { setDescriptionInput(e.target.value); }}
                        fullWidth
                        multiline
                    />
                </Box>

                <Box mt={4}>
                    {movieToProof.reasonsToWatch.map((reason, reasonIndex) => {
                        return (
                            <Box mt={2}>
                                <h3>
                                    Reason #
                                    {reasonIndex + 1}
                                </h3>

                                <Box mt={1}>
                                    <strong>Original reason:</strong>
                                    <Input
                                        type="text"
                                        value={reason.reason}
                                        fullWidth
                                        disabled
                                        multiline
                                    />
                                </Box>
                                <Box mt={1}>
                                    <strong>Rewrited reason reason:</strong>
                                    <Input
                                        type="text"
                                        value={reasonsInputs[reasonIndex].value}
                                        onChange={(e) => {
                                            const newReasons = reasonsInputs.map((reasonInput, reasonInputIndex) => {
                                                if (reasonInputIndex !== reasonIndex) {
                                                    return reasonInput;
                                                }

                                                return {
                                                    value: e.target.value,
                                                    id: reasonInput.id,
                                                };
                                            });
                                            setReasonsInputs(newReasons);
                                        }}
                                        fullWidth
                                        multiline
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>

                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => { handleSubmit(); }}
                    disabled={isSubmitting}
                >
                    Approve
                </Button>
            </Paper>
        );
    };

    return (
        <Box>
            <Typography component="h1" variant="h3">
                Proof Movie Description + reasons why to watch
            </Typography>

            {movieToProof?.status === 'OK' && (
                <div>
                    Remaining to proof:
                    {' '}
                    <strong style={{color: 'red'}}>{movieToProof.toProofreadCount}</strong>
                    <br />
                    Ready to publish:
                    {' '}
                    <strong style={{color: 'green'}}>{movieToProof.readyToPublishCount}</strong>
                </div>
            )}

            {renderContent()}
        </Box>
    );
};
