import {
    Box, Button, Input, Paper, Typography,
} from '@mui/material';
import React, {useState} from 'react';

interface UploadCsvBoxProps {
    title: string;
    note: string;
    uploadApiPath: string;
}

export const UploadCsvBox = (props: UploadCsvBoxProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const uploadFile = async () => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                // You can write the URL of your server or any other endpoint used for file upload
                const result = await fetch(props.uploadApiPath, {
                    method: 'POST',
                    body: formData,
                });
                setIsSubmitting(false);
                alert('Successfully uploaded');
            } catch (error) {
                setIsSubmitting(false);
                console.error(error);
                alert('Error');
            }
        }
    };

    return (
        <Paper sx={{mt: 5, p: 2}}>
            <Typography component="h2" variant="h5">
                {props.title}
            </Typography>
            <Box mt={1}>
                Note:
                {' '}
                {props.note}
            </Box>

            <Box mt={2}>
                <Input
                    type="file"
                    onChange={handleFileChange}
                />
            </Box>

            <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={uploadFile}
                disabled={isSubmitting}
            >
                Upload
            </Button>
        </Paper>
    );
};
