import {useEffect, useState} from 'react';
import {fetchUserMe} from 'api/user/me/fetch';
import {deleteCookie} from 'library/cookie/remove';
import {CookiesNames} from 'library/cookie/cookiesNames';

export const useAuthentication = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

    useEffect(
        () => {
            const fetch = async () => {
                try {
                    const result = await fetchUserMe();

                    setIsAuthenticated(!!result.username);
                } catch {
                    deleteCookie(CookiesNames.JWT_KEY);
                    setIsAuthenticated(false);
                }
            };

            fetch();
        },
        [],
    );

    return {isAuthenticated};
};
