import React from 'react';
import {LoginScreen} from 'screens/login/LoginScreen';
import {useAuthentication} from 'model/auth/useAuthentication';
import {CircularProgress} from '@mui/material';

export const AuthProvider = ({children}: any) => {
    const {isAuthenticated} = useAuthentication();

    if (isAuthenticated === undefined) {
        return <CircularProgress />;
    }

    return isAuthenticated
        ? children
        : <LoginScreen />;
};
