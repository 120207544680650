import React from 'react';
import {Outlet} from 'react-router-dom';
import {AuthProvider} from 'providers/AuthProvider';
import {TopBar} from 'components/layout/topbar/TopBar';
import {Box, Toolbar} from '@mui/material';

const App = () => {
    return (
        <AuthProvider>
            <TopBar />
            <Box sx={{p: 4}}>
                <Toolbar />
                <Outlet />
            </Box>
        </AuthProvider>
    );
};

export default App;
