import {UserIsNotLogged} from 'model/auth/UserIsNotLogged';
import {UserMeResponsePayload} from 'api/user/me/type';
import {getCookie} from 'library/cookie/get';
import {CookiesNames} from 'library/cookie/cookiesNames';

export const fetchUserMe = async (): Promise<UserMeResponsePayload> => {
    const response = await fetch(
        '/admin-api/user/me',
        {
            method: 'get',
            credentials: 'include',
            headers: {
                Authorization: `Bearer ${getCookie(CookiesNames.JWT_KEY)}`,
            },
        },
    );

    if (response.status !== 200) {
        throw new UserIsNotLogged();
    }

    return response.json();
};
