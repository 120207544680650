import {UserLoginResponsePayload} from 'api/user/login/type';

export const fetchLogin = async (username: string, password: string): Promise<UserLoginResponsePayload> => {
    const response = await fetch(
        '/admin-api/user/login',
        {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, password}),
        },
    );

    return response.json();
};
